angular.module('app')
    .factory('jobService', ["$log", "$http", function ($log, $http) {

        var urls = {
            jobs: '/dashboard/organisation/%o/jobs',
            jobDetail: '/dashboard/organisation/%o/job/%o'
        };

        var api = {
            getJobs: function (orgReference) {
                return $http.get(sprintf(urls.jobs, orgReference)).then(function (result) {
                    return result.data;
                });
            },
            getJobDetail: function (orgReference, jobReference) {
                return $http.get(sprintf(urls.jobDetail, orgReference, jobReference)).then(function (result) {
                    return result.data;
                });
            }
        };

        return api;

    }]);